import React, { Component } from 'react';
import {Container, Row, Col, Carousel} from 'react-bootstrap';
import './pageComponent.css';


class Page extends Component {
  render() {
    return (
          <Container className="no-padding" fluid>
              <div className={"splash " + this.props.img}>
              </div>
            <Row className="home-description">
              <Col className="desc-col">
                <h1 className="about">
                  About
                </h1>
                  {this.props.desc}
              </Col>
            </Row>
            <Row>
              <Col>
                <Carousel>
                  {this.props.slideshow && this.props.slideshow.map((value,index)=>{
                    return (
                      <Carousel.Item>
                      <div className="carousel-wrap">
                        <img className="carousel-img" src={value} alt="photo21"></img>
                      </div>
                    </Carousel.Item>
                  );
                  })}
                </Carousel>
              </Col>
            </Row>
            <Row>
              <Col className="contact-wrap">
                <h1 className="contact">
                  Contact
                </h1>
                {this.props.contact}
              </Col>
            </Row>
          </Container>
    );
  }
}


export default Page;
