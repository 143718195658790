import React from 'react';
import './App.css';
import Header from './components/headerComponent/header';
import HomePage from './components/pages/homePageComponent/homePage';
import ContactPage from './components/pages/contactPageComponent/contactPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ColorPage from './components/pages/colorPageComponent/colorPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Route exact path="/" component={HomePage} />
        <Route exact path='/kriztolab' component={ContactPage} />
        <Route exact path='/dcolordiamonds' component={ColorPage} />
      </div>
    </Router>
  );
}


export default App;
