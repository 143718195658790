import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './header.css';

class Header extends Component {
  render(){
    return(
      <header className="header">
        <div className="header-wrapper">
          <div></div>
          <nav className="navbar-wrapper">
            <ul className="navbar">
              <NavLink exact={true} activeClassName='is-active' className='navButton' to='/'>
                  <li><img src='/images/emperorlogo.png' alt="logo" className="logo"></img></li>
              </NavLink>
              <NavLink exact={true} activeClassName='is-active' className='navButton' to ='/dcolordiamonds'>
                  <li><img src='/images/dlogo2.png' alt="logo" className="logo"></img></li>
              </NavLink>
              <NavLink exact={true} activeClassName='is-active' className='navButton' to ='/kriztolab'>
                  <li><img src='/images/kriztolablogo.png' alt="logo" className="logo"></img></li>
              </NavLink>
            </ul>
          </nav>
        </div>
      </header>
    )
  }

}

export default Header;
