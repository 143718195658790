import React, { Component } from 'react';
import './colorPage.css';
import Page from '../pageComponent/pageComponent.jsx';

class ColorPage extends Component {
  render() {
    let description = (
      <div className='about-desc'>
        <li>• 所有鑽石以批發價格出售</li>
<li>• 所有鑽石配有GIA証書</li>
<li>• 大量鑽石以最高顔色D Color提供選擇</li>
<li>• 大量18K白金戒指、吊咀托供選擇</li>
<li>• 歡迎WhatsApp 93193335 或 59395319 查詢</li>
<li>• 敬請預約參觀選購</li>
    </div>);
    let slideshow = [
      "./images/dcolor/dia3.jpeg",
      "./images/dcolor/dia2.jpeg",
      "./images/dcolor/dia4.jpeg",
      "./images/dcolor/dia1.jpeg"
    ];

    let contact = (
<div className="contact-desc">
<p>M(852)93193335</p>

<p>Unit K5, 2/F., Kaiser Estate Phase 2,</p>
<p>47-53 Man Yue Street, Hung Hom, Kowloon, Hong Kong</p>

<p>FB: m.me/DColorDias</p>
<p>E: dcolordias@shukwing.com</p>
</div>
);
    return (
        <main className="contactPage">
          <Page img={"dcolor"} desc={description} slideshow={slideshow} contact={contact}></Page>
        </main>
    );
  }
}

export default ColorPage;
