import React, { Component } from 'react';
import './contactPage.css';
import Page from '../pageComponent/pageComponent.jsx';

class ContactPage extends Component {
  render() {
    let description = (
      <div className='about-desc'>
      <p>Online shop for Healing Crystal and jewellery, healing crystals, meteorites, tumble stones, minerals & more.</p>
        <p> Our healing crystals are high vibrational spiritual tools that helps you manifest your dreams to reality. </p>
         <p>Transform your life with the wisdom and spiritual power of healing crystals now. </p>
    </div>);
    let slideshow = [
      "./images/klabs/klabs1.jpg",
      "./images/klabs/klabs2.jpg",
      "./images/klabs/klabs3.jpg",
      "./images/klabs/klabs4.jpg",
      "./images/klabs/klabs5.jpg",
      "./images/klabs/klabs6.jpg",
      "./images/klabs/klabs7.jpg"
    ];

    let contact = (
      <div className="contact-desc">

<p>M(852)93191415</p>

<p>Unit K5, 2/F., Kaiser Estate Phase 2,</p>
<p>47-53 Man Yue Street, Hung Hom, Kowloon, Hong Kong</p>

<p>FB: m.me/kriztolab</p>
<p>ig: kriztolab</p>
</div>
);
    return (
        <main className="contactPage">
          <Page img={"klabs"} desc={description} slideshow={slideshow} contact={contact}> </Page>
        </main>
    );
  }
}

export default ContactPage;
