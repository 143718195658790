import React, { Component } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import './homePage.css';
import Page from '../pageComponent/pageComponent.jsx';
import pdfFile from './A-B-24-07-07726.pdf';


class HomePage extends Component {
  openPdf = () => {
    window.open(pdfFile, '_blank');
  };

  render() {
    let description = (
      <div className='about-desc'>
        <p >Rare Gems, Top-grade Gems, Semi-precious stones,
      Cut Stones, Jewellery and more.</p>
    <p >Astrologically and
      Gemologically approved gemstones.</p>
    <p>Order Online Today. </p>
    <br></br>
    <a href={pdfFile} target="_blank" rel="noopener noreferrer">Certificate of Registration for Category A Registrant</a>

    </div>);

    let slideshow = [
      "./images/emperor/emperor1.JPG",
      "./images/emperor/emperor2.JPG",
      "./images/emperor/emperor3.JPG",
      "./images/emperor/emperor4.JPG",
      "./images/emperor/emperor5.JPG",
      "./images/emperor/emperor6.JPG",
      "./images/emperor/emperor7.JPG"
    ];

    let contact =
      (  <div className="contact-desc">
                        <p>M: (852)67265588</p>
                        <p>Unit K5, 2/F., Kaiser Estate Phase 2,</p>
                        <p>47-53 Man Yue Street, Hung Hom, Kowloon, Hong Kong</p>
                        <p>香港九龍紅磡民裕街47-53號凱旋工商中心二期2樓K5室</p>
                        <p>T: (852)35231790</p>
                        <p>F: (852)35231791</p>
                        <p>E: emperorcolorstone@gmail.com</p>
                      </div>
                    );
    return (
        <main className="homePage">
          <Page img={"emperor"} desc={description} slideshow={slideshow} contact={contact}> </Page>
        </main>
    );
  }
}


export default HomePage;
